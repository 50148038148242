import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PixelsService } from '../../../shared/pixels.service';

@Component({
    selector: 'app-referral-link-dialog',
    templateUrl: './referral-link-dialog.component.html',
    styleUrls: ['./referral-link-dialog.component.scss'],
    standalone: false
})
export class ReferralLinkDialogComponent {

    public url: string;
    
    constructor(
        private dialogRef: MatDialogRef<ReferralLinkDialogComponent>,
        private pixels: PixelsService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        this.url = `${environment.domain}/referral?referrer_artist_id=${this.data.id}`;
    }

    /**
     * Save the url to clipboard
     */
    public saveToClipboard(): void {
        this.sendOnCopyLinkEvent();
        navigator.clipboard.writeText(this.url);
    }

    /**
     * Close dialog
     */
    public onCloseClick(): void {
        this.sendOnExitPopupEvent();
        this.dialogRef.close();
    }

    /**
     * Sending the event when user clicked on link copy button
     */
    private sendOnCopyLinkEvent(): void {
        this.pixels.sendPixel({
            event: 'click',
            button_name: 'copy_link'
        });
    }

    /**
     * Sending the event when user clicked close popup button
     */
    private sendOnExitPopupEvent(): void {
        this.pixels.sendPixel({
            event: 'click',
            button_name: 'exit_popup'
        });
    }
}